/* -------------- footer --------------*/
.footer{
    background: $c_font;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    .rights{
        color: $c_white;
        font-size: 1.125rem /* 18/16 */;
        line-height: 1.2;
    }
}