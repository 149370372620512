/* -------------- index --------------*/
.index-banner{
    position: relative;
    overflow-x: hidden;
    .main-frame{
        position: relative;
    }
    .banner-content{
        @include abs_pos($l:calc(130 / 1920 * 100%), $b:calc(60 / 600 * 100%));
        width: calc(100% - (130 / 1920 * 100%));
    }
    .banner-text{
        width: calc(1270 / 1920 * 100%);
        img{
            display: block;
            width: 100%;
        }
    }
    .search-no{
        @include abs_pos($b:40%, $l:46%);
        @include maw(430px);
        .title{
            color: $c_font;
            font-size: 2.75rem /* 44/16 */;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 20px;
        }
        .field{
            border: 3px solid $c_font;
            border-radius: 40px;
            display: grid;
            grid-template-columns: auto 70px;
            overflow: hidden;
            input{
                border: none;
                border-right: 2px solid $c_font;
                color: $c_font;
                font-size: 1.0625rem /* 17/16 */;
                padding: 20px 40px;
                width: 100%;
            }
            .submit{
                background: $c_main;
                img{
                    display: block;
                    margin: auto;
                    width: 35px;
                }
                &:hover{
                    background: $c_fontL;
                }
            }
        }
    }
}

.index-countdown{
    padding: 100px 0;
    overflow-x: hidden;
    .ribbon-sec{
        text-align: center;
        margin-bottom: 60px;
        .ribbon{
            position: relative;
            display: inline-block;
            .content{
                background: $c_main;
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px 20px;
                padding: 20px 120px;
                &:before, &:after{
                    @include abs_pos($t:50%);
                    @include size(15px);
                    border-radius: 50%;
                    border: 3px solid $c_font;
                    background: $c_white;
                    content: '';
                    transform: translateY(-50%);
                }
                &:before{
                    left: 60px;
                }
                &:after{
                    right: 60px;
                }
            }
            p{
                color: $c_font;
                font-size: 2.5rem /* 40/16 */;
                font-weight: bold;
                line-height: 1.4;
                span{
                    margin-left: 15px;
                }
            }
            &:before, &:after{
                @include abs_pos($t:0);
                @include size(34px, 100%);
                background: url(../images/ribbon-edge.svg) no-repeat;
                background-size: cover;
                background-position-y: center;
                content: '';
            }
            &:before{
                left: 0;
                transform: translateX(-100%);
            }
            &:after{
                right: 0;
                transform: translateX(100%) rotateZ(180deg);
            }
        }
    }
    #countdown {
        @include flex($jfc:space-between);
        color: $c_font;
        max-width: 1200px;
        margin: auto;
        .circle {
            @include flex($ai:center, $jfc:center);
            @include size(230px);
            position: relative;
            svg {
                @include size(150px);
                position: relative;
                transform: rotate(270deg) scale(1.6);
                circle {
                    width: 100%;
                    height: 100%;
                    fill: transparent;
                    stroke-width: 1;
                    stroke: $c_font;
                    stroke-linecap: round;
                    transform: translate(5px, 5px);
                    &:nth-child(2) {
                        stroke: $c_main;
                        stroke-width: 4;
                        stroke-dasharray: 440;
                        stroke-dashoffset: 440;
                    }
                }
            }
            p{
                @extend %center;
                font-size: 5rem;
                text-align: center;
                width: 100%;
            }
        }
        .title{
            color: $c_font;
            font-size: 1.875rem /* 30/16 */;
            font-weight: bold;
            text-align: center;
            margin-top: 25px;
        }
    }
}

.index-booked{
    position: relative;
    .word-marquee{
        &:nth-child(n+4){
            display: none;
        }
    }
    .booked-list{
        @extend %center;
        @include maw(1100px);
        padding: 30px;
        display: flex;
        gap: 100px;
    }
    .booked-item{
        width: 100%;
        .des{
            color: $c_font;
            font-size: 1.625rem /* 26/16 */;
            line-height: 1.2;
            margin-top: 25px;
            text-align: center;
        }
    }
}

.index-gallery{
    padding: 80px 0 100px;
}