@mixin bg($img){
	background: url("../img/#{$img}");
	display: block;
	width: 100%;
	height: auto;
}

@mixin abs_pos($t: null, $r: null, $b: null, $l: null){
	position: absolute;
	top: $t; right: $r; bottom: $b; left: $l;
}


@mixin size($w, $h: $w){
	width: $w; height: $h;
}
	
@mixin fiximg($w: 100%, $padding-bottom: 75%){
	width: $w; height: 0;
	padding-bottom: $padding-bottom;
	img{
		@extend %inside_img;
	}
}

@mixin placeholder($color, $fz: initial){
	::-webkit-input-placeholder{
		color: $color;
		font-size: $fz;
	}
	:-ms-input-placeholder{
		color: $color;
		font-size: $fz;
	}
	::-moz-placeholder{
		color: $color;
		font-size: $fz;
		opacity: 1
	}
	:-moz-placeholder{
		color: $color;
		font-size: $fz;
		opacity: 1;
	}
}

@mixin flex($ai: stretch, $jfc: flex-start){
	display: flex;
	align-items: $ai;
	justify-content: $jfc;
	flex-wrap: wrap;
}
	

@mixin maw($max-width){
	max-width: $max-width;
	width: 100%;
}

@mixin lineLimit($line) {
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
}