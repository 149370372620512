/* -------------- layout --------------*/
* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	margin-left: auto;
	margin-right: auto;
}

body{
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}
a{
	text-decoration: none;
	transition: all .4s ease;
}

.unreset{
	line-height: initial;
	a{
		text-decoration: underline;
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
}

@keyframes marquee {
	0% { left: -100%; }
	100% { left: 0%; }
}
@keyframes marquee-reverse {
	0% { left: 0%; }
	100% { left: -100%; }
}
.marquee {
	width: 100%;
	overflow: hidden;
	position: relative;
	.limit-row {
		display: block;
		width: 200%;
		height: 100%;
		position: absolute;
		overflow: hidden;
		animation: marquee 8s linear infinite;
	}
	.img-box{
		float: left;
		width: 50%;
	}
	&.reverse{
		.limit-row{
			animation: marquee-reverse 8s linear infinite;
		}
	}
	&.aw-marquee{
		@include fiximg(100%, calc(600 / 1920 * 100%));
		.img-limit{
			@include fiximg(100%, calc(600 / 1920 * 100%));
			background: url(../images/banner-aw.svg) no-repeat;
			position: relative;
		}
	}
	&.word-marquee{
		@include fiximg(100%, calc(66 / 1984 * 100%));
		margin: 20px 0;
		.img-limit{
			@include fiximg(100%, calc(66 / 1984 * 100%));
			background: url(../images/keep-running.svg) no-repeat;
			position: relative;
		}
	}
}

.link-style{
	border-radius: 50px;
	border: 10px solid $c_main;
	background: $c_white;
	box-shadow: 7px 12px 0 $c_font;
	color: $c_font;
	display: block;
	font-size: 1.875rem /* 30/16 */;
	line-height: 1;
	letter-spacing: 1px;
	padding: 15px;
	text-align: center;
	transform: translate(0, 0);
	transition: all .4s ease;
	width: 100%;
	&:hover{
		box-shadow: none;
		transform: translate(7px, 12px);
	}
}

.main-head{
	margin-bottom: 50px;
	text-align: center;
	.text{
		color: $c_font;
		display: inline-block;
		font-size: 3.125rem /* 50/16 */;
		font-weight: bold;
		letter-spacing: 1px;
		padding: 25px 130px;
		position: relative;
		&:before, &:after{
			@include abs_pos($t:50%);
			@include size(95px);
			content: '';
			transform: translateY(-50%);
		}
		&:before{
			background: url(../images/icon-camera.svg) no-repeat;
			background-size: contain;
			background-position: center center;
			left: 5px;
		}
		&:after{
			background: url(../images/icon-stop.svg) no-repeat;
			background-size: contain;
			background-position: center center;
			right: 5px;
		}
		span{
			display: inline-block;
		}
	}
}

.gallery{
	position: relative;
	width: 100%;
	.img-limit{
		@include fiximg(100%, calc(700 / 1100 * 100%));
		position: relative;
	}
	.nav-limit{
		@include abs_pos($t:50%, $l:0);
		opacity: .7;
		transform: translateY(-50%);
		width: 100%;
	}
	.main-limit{
		border-left: 25px solid $c_white;
		border-right: 25px solid $c_white;
		display: block;
		max-width: 1150px;
		margin: auto;
		position: relative;
		padding-bottom: 20px;
	}
	.slick-arrow{
		position: absolute;
		background: none;
		color: $c_main;
		font-size: 1.625rem /* 26/16 */;
		top: 50%;
		transform: translateY(-100%);
		&.slick-disabled{
			opacity: 0;
		}
	}
	.slick-prev{
		left: 20px;
		z-index: 1;
	}
	.slick-next{
		right: 20px;
	}
	.slick-dots{
		display: flex;
		margin-top: 60px;
		width: calc(100% - 280px);
		li{
			flex: 1;
		}
		button{
			@include size(100%, 4px);
			background: $c_font;
			font-size: 0;
		}
		.slick-active button{
			position: relative;
			&:before{
				@include abs_pos($t:-10px, $l:50%);
				@include size(25px);
				background: url(../images/run-man.svg) no-repeat;
				content: '';
				transform: translate(-40%, -100%);
			}
			&:after{
				@include abs_pos($t:-2px, $l:0);
				@include size(100%, 8px);
				border-radius: 5px;
				background: $c_main;
				content: '';
			}
		}
	}
	.gallery-count{
		@include abs_pos($r:0, $b:0);
		@include flex($ai:center);
		.current{
			@include flex($ai:center);
			&:after{
				content: '/';
				color: $c_font;
				font-size: 1.1875rem /* 19/16 */;
				padding: 0 10px;
			}
			span{
				color: $c_fontL;
				font-size: 1.1875rem /* 19/16 */;
				line-height: 1;
			}
			.select-style{
				background: linear-gradient(-90deg, $c_main 30px, $c_white 30px); 
				border: 2px solid $c_font;
				border-radius: 3px;
				margin: 0 10px;
				select{
					font-size: 1.125rem /* 18/16 */;
					padding-right: 38px;
				}
			}
		}
		.total{
			color: $c_font;
			font-size: 1.1875rem /* 19/16 */;
			line-height: 1;
		}
	}
}

.pagination-list{
	@include flex($jfc:flex-end);
	padding-top: 20px;
	padding-bottom: 50px;
	li{
		&:not(:last-child):after{
			content: '/';
			color: $c_fontL;
			display: inline-block;
			padding: 0 5px;
		}
		&.home{
			padding-left: 30px;
			position: relative;
			&:before{
				@include abs_pos($t:0, $l:0);
				@include size(20px);
				background: url(../images/smile.svg) no-repeat;
				background-size: contain;
				content: '';
			}
		}
	}
	a{
		color: $c_fontL;
		font-size: 1rem /* 16/16 */;
		line-height: 1.2;
		&:hover{
			text-decoration: underline;
		}
	}
}

.pg-grid{
	margin-bottom: 90px;
}

.content-grid{
	border: 4px solid $c_font;
	border-radius: 20px;
	overflow: hidden;
	&:not(:first-child){
		margin-top: 100px;
	}
	.content-head{
		border-bottom: 4px solid $c_font;
		background: $c_main;
		font-size: 2.25rem /* 36/16 */;
		font-weight: bold;
		line-height: 1;
		padding: 20px;
		text-align: center;
	}
	.content-sub-head{
		border-bottom: 4px solid $c_font;
		color: $c_font;
		font-size: 1.75rem /* 28/16 */;
		font-weight: bold;
		line-height: 1.4;
		padding: 15px;
		text-align: center;
		span{
			display: inline-block;
		}
	}
	.content{
		padding: 40px 60px;
		&.no-padding{
			padding: 0;
		}
		.center-img{
			display: block;
			margin: 0 auto;
			max-width: 100%;
			&.line{
				border: 1px solid $c_font;
			}
		}
		img{
			display: block;
			max-width: 100%;
		}
		p{
			color: $c_font;
			font-size: 1.75rem /* 28/16 */;
			line-height: 1.5;
		}
		a{
			color: $c_font;
			text-decoration: underline;
			&:hover{
				color: $c_main;
			}
		}
		.content-title{
			align-items: baseline;
			display: grid;
			grid-template-columns: 25px auto;
			gap: 12px;
			> img{
				margin-top: 7px;
			}
			> svg{
				font-size: 1.625rem /* 26/16 */;
				margin-top: 7px;
			}
			p{
				font-weight: bold;
			}
		}
		.des-group{
			display: flex;
			span{
				display: inline-block;
				flex-shrink: 0;
			}
		}
		table{
			width: 100%;
			th, td{
				padding: 15px 3.5vw;
				&:nth-child(n+2){
					border-left: 4px solid $c_font;
				}
			}
			th{
				background: #f5f3f2;
				text-align: center;
			}
			td{
				border-top: 4px solid $c_font;
			}
		}
		ul{
			li{
				padding-left: 15px;
				position: relative;
				&:before{
					@include abs_pos($t:17px, $l:0);
					@include size(7px);
					border-radius: 50%;
					background: $c_font;
					content: '';
				}
				&:nth-child(n+2){
					margin-top: 5px;
				}
			}
		}
		ol{
			margin-left: 60px;
			li{
				font-size: 1.75rem /* 28/16 */;
				list-style: cjk-ideographic;
				&:nth-child(n+2){
					margin-top: 5px;
				}
			}
		}
		.step-group{
			@include flex;
			.step{
				position: relative;
				&:after{
					content: '\f35a';
					font-family: 'Font Awesome 6 Free';
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
		.center-text{
			text-align: center;
			font-weight: bold;
			margin-bottom: 20px;
		}
		.inner-table{
			border-radius: 10px;
			border: 4px solid $c_font;
			overflow: hidden;
			&:not(:last-child){
				margin-bottom: 60px;
			}
			table{
				&:not(:last-child){
					border-bottom: 4px solid $c_font;
				}
				td, th{
					padding: 13px 2.5vw;
				}
			}
		}
	}
	.img-list{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;
		.img-des{
			color: $c_font;
			font-size: 1.75rem /* 28/16 */;
			font-weight: bold;
			margin-bottom: 20px;
			text-align: center;
		}
	}
	.row-item{
		align-items: center;
		display: grid;
		grid-template-columns: 50% auto;
		gap: 40px 60px;
		&:not(:last-child){
			margin-bottom: 30px;
		}
		.title{
			font-weight: bold;
			margin-bottom: 10px;
		}
		.img-item{
			&.multiple{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
			}
			img{
				display: block;
				width: 100%;
			}
			.img-text{
				text-align: center;
				margin-top: 10px;
			}
		}
	}
	.relay-participate{
		&:not(:last-child){
			margin-bottom: 40px;
		}
		p{
			font-weight: bold;
			margin-bottom: 20px;
		}
		.participate-img-grid{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;
			.wide{
				grid-column: 1 / 3;
			}
		}
	}
}

.content-multi-grid{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 60px;
	.content-grid{
		&:nth-child(1), &:nth-child(2){
			margin-top: 0;
		}
	}
}

.activity-list{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 60px;
	.activity-item{
		border: 3px solid $c_font;
		border-radius: 10px;
		overflow: hidden;
		.activity-name{
			background: #f5f3f2;
			border-bottom: 3px solid $c_font;
			font-weight: bold;
			text-align: center;
			padding: 12px 15px;
		}
		.activity-content{
			padding: 30px;
			p{
				text-align: center;
				margin-top: 15px;
			}
		}
	}
}