/* -------------- header RWD --------------*/
@media (max-width: 1700px){ 
    .header{
        .right-text{
            width: calc(640 / 1920 * 100%);
        }
    }
}

@media (max-width: 1600px){ 
    .header{
        padding: 40px;
        .right-text{
            width: calc(540 / 1920 * 100%);
        }
    }
}

@media (max-width: $w_pc_b){ 
    .header{
        .logo{
            width: 80px;
        }
        .main-menu{
            gap: 15px;
            .main-link{
                padding: 5px;
                span{
                    font-size: 1.0625rem /* 17/16 */;
                    padding: 10px 20px;
                }
            }
        }
        .sub-menu{
            padding: 7px;
            width: 140px;
            .sub-link span{
                padding: 10px;
            }
        }
    }
}

@media (max-width: $w_pc){ 
    .header{
        .main-menu{
            gap: 10px;
        }
        .right-text{
            width: 27%;
        }
    }
}

@media (max-width: 1200px){ 
    .header{
        padding: 20px 40px;
        .logo{
            width: 70px;
        }
        .main-menu{
            .main-link{
                span{
                    font-size: 1rem /* 16/16 */;
                }
            }
        }
        .right-text{
            width: calc(500 / 1920 * 100%);
        }
    }
}

@media (max-width: 1100px){ 
    .header{
        padding-right: 120px;
        .main-menu{
            background: rgba($c_font, .85);
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            margin-top: 0;
            padding: 25px;
            width: 200px;
            height: 100dvh;
            display: none;
            > li:not(:last-child){
                margin-bottom: 15px;
            }
            .main-link{
                text-align: center;
            }
        }
        .sub-menu{
            position: static;
            transform: translate(0, 0);
            width: 100%;
        }
        .right-text{
            margin-top: 0;
            width: 270px;
        }
    }
    .mobile-toggle{
        display: block;
    }
}

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .header{
        padding: 20px;
        padding-right: 80px;
        .logo{
            width: 50px;
        }
        .right-text{
            width: 200px;
        }
    }
    .mobile-toggle{
        right: 20px;
    }
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }