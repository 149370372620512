/* -------------- header --------------*/
.header{
    @include flex($jfc:space-between);
    padding: 40px 80px 40px 50px;
    position: relative;
    .logo{
        display: block;
        width: 90px;
        img{
            display: block;
            width: 100%;
        }
    }
    .main-menu{
        @include flex;
        gap: 25px;
        margin-top: 10px;
        position: relative;
        z-index: 1;
        .has-sub{
            position: relative;
        }
        .main-link{
            background: $c_main;
            border-radius: 30px;
            display: block;
            padding: 8px;
            span{
                border: 2px solid $c_font;
                border-radius: inherit;
                color: $c_font;
                display: block;
                font-size: 1.125rem /* 18/16 */;
                font-weight: bold;
                padding: 10px 25px;
                position: relative;
                &:before, &:after{
                    @include abs_pos($t:50%);
                    @include size(5px);
                    border-radius: 50%;
                    background: $c_font;
                    content: '';
                    transform: translateY(-50%);
                }
                &:before{
                    left: 8px;
                }
                &:after{
                    right: 8px;
                }
            }
            &:hover{
                background: $c_font;
                span{
                    border-color: $c_main;
                    color: $c_main;
                    &:before, &:after{
                        background: $c_main;
                    }
                }
            }
        }
    }
    .sub-menu{
        @include abs_pos($b:-20px, $l:50%);
        border: 2px solid $c_font;
        background: $c_main;
        border-radius: 30px;
        padding: 10px;
        transform: translate(-50%, 100%);
        width: 180px;
        display: none;
        > li{
            &:first-child .sub-link{
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
            }
            &:last-child .sub-link{
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
            }
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
        .sub-link{
            background: $c_white;
            border: 2px solid $c_font;
            display: block;
            overflow: hidden;
            span{
                color: $c_font;
                display: block;
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                text-align: center;
                padding: 15px;
            }
            &:hover span{
                background: $c_font;
                color: $c_main;
            }
        }
    }
    .right-text{
        margin-top: 20px;
        width: calc(720 / 1920 * 100%);
        img{
            display: block;
            width: 100%;
        }
    }
}

.mobile-toggle {
    @include abs_pos($t:50%, $r:40px);
    @include size(45px, 45px);
    background: $c_main;
    display: none;
    transform: translateY(-50%);
    padding: 10px;
    z-index: 4;

    &.open {

        span,
        span:before,
        span:after {
            background: $c_white;
        }

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 4px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: $c_font;
        transition: all .4s ease;
    }
}