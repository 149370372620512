/* -------------- footer RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .footer{
        padding-top: 20px;
        padding-bottom: 20px;
        .rights{
            font-size: 1rem /* 16/16 */;
        }
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .footer{
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

// @media (max-width: $w_mo){ }