/* -------------- container RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .frame{
		padding-left: 20px;
		padding-right: 20px;
	}
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }