/* -------------- pagination --------------*/
.page_box {
    @include flex($jfc: center);
    margin-top: 40px;

    .page_num {
        @include size(36px);
        @include flex($ai: center, $jfc: center);
        border: 1px solid #ccc;
        background: $c_white;
        color: $c_main;
        font-size: 1rem
            /* 16/16 */
        ;
        margin-left: 8px;
        margin-right: 8px;

        &:hover {
            background: #eee;
        }

        &.active {
            background: $c_main;
            border-color: $c_main;
            color: $c_white;
        }

        i {
            font-size: 1rem
                /* 16/16 */
            ;
        }
    }
}