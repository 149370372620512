/* -------------- form --------------*/
button, input[type="reset"], input[type="submit"]{
	cursor: pointer;
}

input[type="reset"], input[type="submit"], input[type="text"], input[type="email"], input[type="tel"], textarea, select, input[type="date"], input[type="time"], input[type="number"], input[type="password"], input[type="url"], button{
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
	box-shadow: none;
	border: none;
	outline: none;
}

select::-ms-expand{
	display: none;
}

.select-style{
	background: $c_white;
    position: relative;
    &:before{
        @include abs_pos($t: 50%, $r: 10px);
        content: '\f0dd';
        display: block;
        font-family: 'Font Awesome 6 Free';
        font-weight: bold;
        color: $c_font;
        transform: translateY(-80%);
    }
    select{
        background: transparent;
        padding: 10px;
        padding-right: 25px;
        position: relative;
        width: 100%;
        z-index: 1;
    }
}

.form-style{
    &.result-form .field{
        .field-label{
            margin-top: 0;
        }
    }
    .field-group{
        &:not(:last-child){
            margin-bottom: 80px;
        }
    }
	.group-title{
        color: $c_font;
		font-size: 1.875rem /* 30/16 */;
        font-weight: bold;
        margin-bottom: 30px;
	}
    .field{
        align-items: flex-start;
        display: grid;
        grid-template-columns: 200px auto;
        gap: 10px 20px;
        &:not(:last-child){
            margin-bottom: 40px;
        }
        &.check-list{
            .field-label{
                margin-top: 0;
            }
        }
        &.address-field{
            .top-row{
                display: grid;
                grid-template-columns: auto 35% 35%;
                gap: 20px 30px;
                margin-bottom: 20px;
            }
        }
        &.clothe-field{
            .field-content{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }
        }
        .field-label{
            color: $c_font;
            font-size: 1.875rem /* 30/16 */;
            font-weight: bold;
            line-height: 1;
            margin-top: 15px;
        }
        .result-text{
            color: $c_font;
            font-size: 1.875rem /* 30/16 */;
            line-height: 1;
        }
        .check-item{
            margin-bottom: 5px;
            &:not(:last-child){
                margin-right: 20px;
            }
        }
        [readonly]{
            background: #f5f3f2;
        }
        input[type="text"]{
            border-radius: 10px;
            border: 4px solid $c_font;
            color: $c_font;
            font-size: 1.25rem /* 20/16 */;
            line-height: 1.2;
            padding: 15px;
            width: 100%;
        }
        input[type="radio"]{
            display: none;
            &:checked + span:after{
                transform: scale(1);
            }
            & + span{
                color: $c_font;
                display: inline-block;
                font-size: 1.75rem /* 28/16 */;
                line-height: 1.2;
                padding-left: 30px;
                position: relative;
                &:before, &:after{
                    border-radius: 50%;
                    content: '';
                }
                &:before{
                    @include abs_pos($t:4px, $l:0);
                    @include size(16px);
                    border: 3px solid $c_font;
                }
                &:after{
                    @include abs_pos($t:9px, $l:5px);
                    @include size(12px);
                    background: $c_font;
                    transform: scale(0);
                    transition: all .4s ease;
                }
            }
        }
        .remark{
            color: #e60012;
            display: grid;
            font-size: 1.125rem /* 18/16 */;
            font-weight: bold;
            line-height: 1.4;
            grid-template-columns: 20px auto;
            gap: 10px;
            margin-top: 10px;
            svg{
                margin-top: 3px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .select-style{
            background: linear-gradient(-90deg, #f5f3f2 33px, $c_font 33px, $c_font 37px, $c_white 37px);
            border: 4px solid $c_font;
            border-radius: 10px;
            select{
                color: $c_font;
                font-size: 1.25rem /* 20/16 */;
                line-height: 1.2;
                padding: 15px;
                padding-right: 33px;
                width: 100%;
            }
        }
    }
    .bottom-comment{
        margin-top: 50px;
        margin-bottom: 70px;
        .sec-title{
            font-weight: bold;
            margin-bottom: 10px;
        }
        p{
            color: $c_font;
			font-size: 1.75rem /* 28/16 */;
			line-height: 1.5;
        }
        ul{
			li{
				padding-left: 15px;
				position: relative;
				&:before{
					@include abs_pos($t:17px, $l:0);
					@include size(7px);
					border-radius: 50%;
					background: $c_font;
					content: '';
				}
				&:nth-child(n+2){
					margin-top: 5px;
				}
			}
		}
		ol{
			margin-left: 60px;
			li{
				font-size: 1.75rem /* 28/16 */;
				list-style: cjk-ideographic;
				&:nth-child(n+2){
					margin-top: 5px;
				}
			}
		}
    }
    .term-field{
        @include flex($jfc:center);
        margin-top: 20px;
        label{
            position: relative;
        }
        input[type="checkbox"]{
            @include abs_pos($t:8px, $l:5px);
            margin: 0;
            transform: scale(1.5);
        }
        span{
            font-size: 1.5rem /* 24/16 */;
            display: inline-block;
            line-height: 1.3;
            padding-left: 40px;
        }
    }
    .btn-row{
        @include maw(80%);
        display: flex;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        gap: 100px;
    }
	.clothe-row{
		.title{
			color: $c_font;
			font-size: 1.875rem /* 30/16 */;
            font-weight: bold;
			margin-bottom: 20px;
		}
		img{
			display: block;
			width: 100%;
		}
	}
}
