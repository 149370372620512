/* -------------- index RWD --------------*/
@media (max-width: 1600px){ 
    .index-banner{
        .search-no{
            max-width: 380px;
            .title{
                font-size: 2.25rem /* 36/16 */;
                margin-bottom: 10px;
            }
            .field{
                grid-template-columns: auto 60px;
                input{
                    padding: 15px 30px;
                }
                .submit{
                    img{
                        width: 30px;
                    }
                }
            }
        }
    }
    .index-booked{
        .word-marquee{
            &:nth-child(4){
                display: block;
            }
        }
        .booked-list{
            gap: 60px;
        }
        .booked-item{
            .des{
                font-size: 1.25rem /* 20/16 */;
            }
        }
    }
}

@media (max-width: $w_pc_b){ 
    .index-banner{
        .search-no{
            max-width: 300px;
            .title{
                font-size: 1.875rem /* 30/16 */;
            }
            .field{
                input{
                    padding: 12px 20px;
                }
            }
        }
    }
    .index-countdown {
        padding: 70px 0;
        .ribbon-sec .ribbon p{
            font-size: 1.875rem /* 30/16 */;
        }
    }
}

@media (max-width: $w_pc){ 
    .index-countdown{
        #countdown {
            .circle {
                @include size(190px);
                svg {
                    @include size(120px);
                }
                p{
                    font-size: 4rem /* 64/16 */;
                }
            }
            .title{
                font-size: 1.5rem /* 24/16 */;
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .index-banner{
        .search-no{
            position: static;
            padding: 20px;
            margin-left: auto;
            margin-right: auto;
            max-width: 400px;
            .title{
                text-align: center;
            }
        }
    }
    .index-countdown {
        padding: 20px 0 40px;
        .ribbon-sec .ribbon p{
            font-size: 1.5rem /* 24/16 */;
        }
        #countdown {
            .circle {
                @include size(150px);
                svg {
                    @include size(90px);
                }
                p{
                    font-size: 3.125rem /* 50/16 */;
                }
            }
            .title{
                font-size: 1.25rem /* 20/16 */;
                margin-top: 20px;
            }
        }
    }
    .index-gallery {
        padding: 60px 0 80px;
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .index-countdown .ribbon-sec {
        padding-left: 15px;
        padding-right: 15px;
    }
    .index-booked{
        .booked-list{
            gap: 30px;
        }
        .booked-item{
            .link{
                box-shadow: 5px 10px 0 $c_font;
                font-size: 1.375rem /* 22/16 */;
                &:hover{
                    transform: translate(5px, 10px);
                }
            }
            .des{
                font-size: 1.25rem /* 20/16 */;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .index-banner{
        .search-no{
            .title{
                font-size: 1.375rem /* 22/16 */;
            }
            .field{
                grid-template-columns: auto 50px;
                input{
                    padding: 10px 20px;
                }
                .submit{
                    img{
                        width: 25px;
                    }
                }
            }
        }
    }
    .index-countdown {
        padding-bottom: 20px;
        .ribbon-sec {
            margin-bottom: 40px;
            .ribbon {
                .content{
                    display: block;
                    padding: 15px 50px;
                    &:before, &:after{
                        @include size(10px);
                    }
                    &:before{
                        left: 15px;
                    }
                    &:after{
                        right: 15px;
                    }
                }
                p{
                    font-size: 1.25rem /* 20/16 */;
                }
            }
        }
        #countdown {
            .circle {
                @include size(80px);
                svg {
                    @include size(50px);
                }
                p{
                    font-size: 1.875rem /* 30/16 */;
                }
            }
            .title{
                font-size: 1.0625rem /* 17/16 */;
                margin-top: 15px;
            }
        }
    }
    .index-booked{
        .word-marquee{
            &:nth-child(n+4){
                display: block;
            }
        }
        .booked-list{
            display: block;
        }
        .booked-item{
            margin-top: 20px;
            .link{
                border-width: 5px;
                box-shadow: 4px 7px 0 $c_font;
                font-size: 1.25rem /* 20/16 */;
                padding: 12px;
                &:hover{
                    transform: translate(4px, 7px);
                }
            }
            .des{
                font-size: 1.125rem /* 18/16 */;
                margin-top: 15px;
            }
        }
    }
    .index-gallery {
        padding: 20px 0 30px;
    }
}

@media (max-width: $w_mo){ 
    .index-countdown .ribbon-sec {
        padding: 0;
        .ribbon {
            .content{
                padding: 10px 30px;
                height: 72px;
                &:before, &:after{
                    @include size(7px);
                    border-width: 2px;
                }
                &:before{
                    left: 10px;
                }
                &:after{
                    right: 10px;
                }
            }
            p{
                font-size: 1.125rem /* 18/16 */;
            }
        }
    }
}