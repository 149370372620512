$c_font: #000000;
$c_fontL: #959595;
$c_white: #fff;
$c_main: #72f46c;
$c_dark: #334a8d;
$c_active: #ef7b3a;
$c_grey: #e2e2e2;

$w_pc_b: calc(1440px + 40px);
$w_pc: 1280px;
$w_pc_m: 1024px;
$w_tb_b: 960px;
$w_tb: 768px;
$w_tb_m: 640px;
$w_mo: 480px;
$w_i6p: 414px;
$w_i6: 375px;
$w_mo_m: 320px;

$fz: 10px;

$fa-font-path: "../webfonts";