.error-row{
    background: url(../images/error-background.svg) no-repeat;
    background-position: center top;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    .img-box{
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 100%;
        img{
            display: block;
            width: 100%;
        }
        .error-code{
            color: #555;
            font-size: 120px;
            font-weight: bold;
            letter-spacing: 5px;
            line-height: 1;
            margin: 0;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translateX(-57%);
        }
    }
    .content{
        margin-top: 50px;
        text-align: center;
        .title{
            color: $c_font;
            font-size: 30px;
            font-weight: bold;
            line-height: 1.2;
            margin: 0;
        }
        .des{
            margin-top: 25px;
            p{
                color: #555;
                font-size: 18px;
                line-height: 1.6;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 5px;
                }
            }
        }
    }
    .btn-row{
        margin-top: 80px;
        .link{
            border-radius: 30px;
            background: $c_font;
            color: #fff;
            display: block;
            font-size: 24px;
            padding: 15px 13px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            text-decoration: inherit;
            text-transform: uppercase;
            transition: all .4s;
            width: 240px;
            &:hover{
                background: $c_main;
                color: $c_font;
            }
        }
    }
}

@media (max-width:1440px){
    .error-row {
        .img-box{
            max-width: 540px;
            .error-code{
                font-size: 100px;
            }
        }
        .btn-row{
            margin-top: 50px;
        }
    }
}

@media (max-width:1024px){
    .error-row {
        .img-box{
            max-width: 440px;
            .error-code{
                font-size: 70px;
                top: 45%;
            }
        }
        .btn-row{
            margin-top: 50px;
        }
    }
}

@media (max-width:640px){
    .error-row {
        .img-box{
            .error-code{
                font-size: 50px;
                top: 45%;
            }
        }
        .content{
            margin-top: 30px;
            .title{
                font-size: 26px;
            }
            .des{
                margin-top: 15px;
                p{
                    font-size: 16px;
                }
            }
        }
        .btn-row{
            margin-top: 30px;
            .link{
                font-size: 17px;
                max-width: 170px;
            }
        }
    }
}