/* -------------- layout RWD --------------*/
@media (max-width: 1600px){ 
    .link-style{
        border: 8px solid $c_main;
        box-shadow: 7px 12px 0 $c_font;
        font-size: 1.625rem /* 26/16 */;
        padding: 13px 15px;
        &:hover{
            transform: translate(7px, 12px);
        }
    }
}

@media (max-width: $w_pc_b){ 
    .content-grid{
        .content-head{
            font-size: 1.875rem /* 30/16 */;
        }
        .content-sub-head{
            font-size: 1.5rem /* 24/16 */;
        }
        .content{
            p{
                font-size: 1.375rem /* 22/16 */;
            }
            .content-title{
                > img, > svg{
                    margin-top: 0;
                }
                > svg{
                    font-size: 1.375rem /* 22/16 */;
                }
            }
            ul{
                li:before{
                    top: 12px;
                }
            }
            ol {
                margin-left: 45px;
                li{
                    font-size: 1.375rem /* 22/16 */;
                }
            }
        }
        .img-list .img-des{
            font-size: 1.375rem /* 22/16 */;
        }
    }
    .activity-list{
        gap: 40px;
    }
}

@media (max-width: $w_pc){ 
    .gallery{
        .main-limit{
            max-width: 1000px;
        }
    }
    .content-grid .content .inner-table:not(:last-child){
        margin-bottom: 40px;
    }
    .activity-list .activity-item .activity-content{
        padding: 20px;
    }
}

@media (max-width: 1200px){ 
    .content-grid{
        .img-list{
            grid-template-columns: 1fr;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .gallery{
        .nav-limit{
            display: none;
        }
        .main-limit{
            border: none;
            max-width: 100%;
        }
    }
    .main-head {
        margin-bottom: 40px;
        .text{
            font-size: 2.5rem /* 40/16 */;
            padding: 15px 100px;
            &:before, &:after{
                @include size(75px);
            }
        }
    }
    .pagination-list{
        justify-content: center;
        padding-top: 0;
    }
    .content-grid .row-item{
        gap: 30px 40px;
    }
    .content-multi-grid{
        display: block;
        .content-grid{
            &:nth-child(2){
                margin-top: 100px;
            }
        }
    }
    .activity-list{
        gap: 20px;
    }
}

@media (max-width: $w_tb_b){ 
    .content-grid{
        &:not(:first-child){
            margin-top: 60px;
        }
        .content-head{
            font-size: 1.625rem /* 26/16 */;
        }
        .content-sub-head{
            font-size: 1.25rem /* 20/16 */;
        }
        .content{
            padding: 30px;
            p{
                font-size: 1.1875rem /* 19/16 */;
            }
            ul{
                li:before{
                    top: 9px;
                }
            }
            table{
                tr, td{
                    padding: 11px 2.5vw;
                }
            }
        }
        .img-list .img-des{
            font-size: 1.1875rem /* 19/16 */;
        }
    }
    .content-multi-grid{
        .content-grid{
            &:nth-child(2){
                margin-top: 60px;
            }
        }
    }
}

@media (max-width: $w_tb){ 
    .marquee {
        &.word-marquee{
            padding-bottom: calc(66 / 975 * 100%);
            .img-limit{
                background: url(../images/keep-running-mobile.svg) no-repeat;
                padding-bottom: calc(66 / 975 * 100%);
            }
        }
    }
    .gallery{
        .slick-dots{
            margin-top: 40px;
            width: 100%;
        }
        .gallery-count{
            position: static;
            justify-content: center;
            margin-top: 25px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .link-style{
        border-width: 5px;
        box-shadow: 4px 7px 0 $c_font;
        font-size: 1.25rem /* 20/16 */;
        padding: 12px;
        &:hover{
            transform: translate(4px, 7px);
        }
    }
    .main-head {
        margin-bottom: 30px;
        .text{
            font-size: 1.75rem /* 28/16 */;
            padding: 15px 70px;
            &:before, &:after{
                @include size(55px);
            }
        }
    }
    .gallery{
        .slick-prev{
            left: 10px;
        }
        .slick-next{
            right: 10px;
        }
    }
    .pagination-list{
        padding-bottom: 20px;
    }
    .pg-grid{
        margin-bottom: 40px;
    }    
    .content-grid{
        &:not(:first-child){
            margin-top: 40px;
        }
        .content-head{
            font-size: 1.25rem /* 20/16 */;
            padding: 15px 20px;
        }
        .content-sub-head{
            font-size: 1.125rem /* 18/16 */;
            padding: 10px;
        }
        .content{
            padding: 30px 15px;
            .content-title{
                grid-template-columns: 20px auto;
                gap: 10px;
                > svg{
                    font-size: 1.0625rem /* 17/16 */;
                    margin-top: 2px;
                }
            }
            p{
                font-size: 1.0625rem /* 17/16 */;
            }
            a{
                font-size: 1rem /* 16/16 */;
            }
            ul{
                li:before{
                    top: 9px;
                }
            }
            ol {
                margin-left: 32px;
                li{
                    font-size: 1.0625rem /* 17/16 */;
                }
            }
            table{
                tr, td{
                    padding: 10px 15px;
                }
            }
            .inner-table:not(:last-child){
                margin-bottom: 30px;
            }
        }
        .row-item{
            grid-template-columns: 1fr;
            gap: 15px;
            .img-item.multiple{
                gap: 10px;
            }
        }
        .img-list .img-des{
            font-size: 1.0625rem /* 17/16 */;
        }
    }
    .content-multi-grid{
        .content-grid{
            &:nth-child(2){
                margin-top: 40px;
            }
        }
    }
    .activity-list{
        grid-template-columns: 1fr;
    }
}

// @media (max-width: $w_mo){ }