/* 修正粗體 ASCII (碧筵綰) */
@font-face {
	font-family: 'fix Microsoft JhengHei';
	font-style: normal;
	font-weight: bold;
	unicode-range: U+7db0, U+78A7, U+7B75;
	src: url('../webfonts/msgothic.otf');
}

// FontAwesome
@import "./fontawesome/scss/fontawesome.scss";
@import "./fontawesome/scss/solid.scss";
@import "./fontawesome/scss/brands.scss";
@import "./fontawesome/scss/regular.scss";