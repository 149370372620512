/* -------------- form RWD --------------*/
@media (max-width: 1600px){ 
    .form-style{
        .btn-row{
            gap: 60px;
        }
    }
}

@media (max-width: $w_pc_b){ 
    .form-style{
        .field{
            grid-template-columns: 160px auto;
            &:not(:last-child){
                margin-bottom: 30px;
            }
            .field-label{
                font-size: 1.375rem /* 22/16 */;
            }
            .result-text{
                font-size: 1.375rem /* 22/16 */;
            }
            input[type="text"]{
                font-size: 1.125rem /* 18/16 */;
                padding: 13px;
            }
            input[type="radio"]{
                & + span{
                    font-size: 1.375rem /* 22/16 */;
                    &:before{
                        top: 2px;
                    }
                    &:after{
                        top: 7px;
                    }
                }
            }
            .select-style{
                select{
                    padding: 13px;
                    padding-right: 33px;
                }
            }
        }
        .bottom-comment{
            p{
                font-size: 1.375rem /* 22/16 */;
            }
            ul{
                li:before{
                    top: 12px;
                }
            }
            ol {
                margin-left: 45px;
                li{
                    font-size: 1.375rem /* 22/16 */;
                }
            }
        }
        .clothe-row .title{
            font-size: 1.375rem /* 22/16 */;
        }
    }
}

@media (max-width: $w_pc){ 
    .form-style{
        .field-group{
            &:not(:last-child){
                margin-bottom: 60px;
            }
        }
        .group-title{
            font-size: 1.625rem /* 26/16 */;
        }
        .field{
            .remark{
                font-size: 1rem /* 16/16 */;
            }
        }
        .term-field{
            input[type="checkbox"]{
                top: 6px;
            }
            span{
                font-size: 1.25rem /* 20/16 */;
                padding-left: 30px;
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .form-style{
        .field{
            grid-template-columns: 130px auto;
            &.address-field .top-row{
                gap: 10px 15px;
                margin-bottom: 15px;
            }
            &:not(:last-child){
                margin-bottom: 20px;
            }
            .field-label{
                font-size: 1.1875rem /* 19/16 */;
            }
            .result-text{
                font-size: 1.1875rem /* 19/16 */;
            }
            input[type="text"]{
                font-size: 1.0625rem /* 17/16 */;
                padding: 10px 13px;
            }
            input[type="radio"]{
                & + span{
                    font-size: 1.1875rem /* 19/16 */;
                    &:before{
                        top: 0;
                    }
                    &:after{
                        top: 5px;
                    }
                }
            }
            .select-style{
                select{
                    font-size: 1.1875rem /* 19/16 */;
                    padding: 10px;
                    padding-right: 33px;
                }
            }
        }
    }
}

@media (max-width: $w_tb_b){ 
    .form-style{
        .bottom-comment{
            margin-top: 40px;
            margin-bottom: 50px;
            p{
                font-size: 1.1875rem /* 19/16 */;
            }
            ul{
                li:before{
                    top: 9px;
                }
            }
            table{
                tr, td{
                    padding: 11px 2.5vw;
                }
            }
        }
    }
}

@media (max-width: $w_tb){ 
    .form-style{
        &.result-form .field{
            .field-label{
                border-left: 6px solid #55e34e;
                padding-left: 10px;
            }
        }
        .field{
            grid-template-columns: 1fr;
            .field-label{
                margin-top: 0;
            }

        }
        .btn-row{
            gap: 30px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .form-style{
        .field.address-field .top-row{
            grid-template-columns: repeat(2, 1fr);
            .select-style{
                grid-row-start: 2;
            }
        }
        .bottom-comment{
            margin-top: 30px;
            margin-bottom: 30px;
            p{
                font-size: 1.0625rem /* 17/16 */;
            }
            ul{
                li:before{
                    top: 9px;
                }
            }
            ol {
                margin-left: 32px;
                li{
                    font-size: 1.0625rem /* 17/16 */;
                }
            }
        }
        .term-field{
            input[type="checkbox"]{
                top: 6px;
            }
            span{
                font-size: 1.125rem /* 18/16 */;
            }
        }
        .btn-row{
            display: block;
            margin-top: 30px;
            >:not(:last-child){
                margin-bottom: 20px;
            }
        }
    }
}

// @media (max-width: $w_mo){ }